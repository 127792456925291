
$.fn.preregister = function(options){
    var defaults = {
      obj: $(this),
      elStage: {
          form: '#formPreregister',
          success: '#popupRegisterSuccess',
          fail: '#popupRegisterFail'
      },
      elSubmit: '#submit',
      elRetry: '#retry',
      elTotal: '#total',
      elError: '#error',
      elCheatMultiple: '#preregisterMultiple',
      elCheatAdd: '#preregisterAdd',
      elLuckyNumber: '#luckyNumber',
      elMileStones: '.milestones__bar__active',
      elMileStonesMobile: '.milestones__bar__active--point',
      elRewards: '.milestones__rewards',
      percentage: {
          milestones: [10000, 50000, 100000, 300000, 500000, 1000000],
          ajustDesktop: [6, 17, 28.5, 39.5, 51, 67],
          ajustMobile: [153, 338, 507, 676, 847, 1000],
          ajustMobilePoint: [103, 272, 441, 612, 783, 954]
      },
      validateList: [
          {
            type: 'gcaptcha',
            error: 'Có lỗi xảy ra'
          },
          {
            el: '#email',
            type: 'email',
            error: 'Bạn vui lòng kiểm tra lại email'
          },
          {
            el: '#phone',
            type: 'phone',
            error: 'Bạn vui lòng kiểm tra lại số điện thoại'
          }
      ]
    }
    var settings = $.extend(defaults, options);

    settings.obj.find(settings.elSubmit).bind("click", function(e){
        e.preventDefault();

        // var validateResult = (validateEmail(settings.obj.find("#email").val())) && (validatePhone(settings.obj.find("#phone").val()));

        const validateResult = function(){
            let isValidated = true;
            settings.validateList.forEach(function(item) {
                let val = settings.obj.find(item.el).val();
                // console.log(val);
                switch (item.type) {
                    case 'email': 
                        let validateResultEmail = validateEmail(val);
                        if (!validateResultEmail) {
                            $(settings.elError).text(item.error);
                            isValidated = false;
                            return;
                        }
                        break;
                    case 'phone': 
                        let validateResultPhone = validatePhone(val);
                        if (!validateResultPhone) {
                            $(settings.elError).text(item.error);
                            isValidated = false;
                            return;
                        }
                        break;
                    case 'default': 
                        break;
                }
            });
            return isValidated;
        }

        // validateResult();
        // console.log(validateResult());

        if (validateResult()) {
            var dataPost = settings.obj.find(settings.elStage.form).serialize();
            var urlPost = domain + '/' + "pre-register.json";
            // console.log(dataPost);
            
            $.ajax({
                url: urlPost,
                dataType: 'json',
                method: 'POST',
                data: dataPost,
                success: function(data) {
                    console.log(data);
                    if (data.regSuccess != 1) {
                        // $(settings.elStage.form).css({display: 'none'});
                        grecaptcha.reset();
                        $(settings.elError).text(data.errors.captcha);
                        $(settings.elStage.fail).addClass('active');
                    } else {
                        // $(settings.elStage.form).css({display: 'none'});
                        $(settings.elStage.success).addClass('active');
                        $("#registeredEmail").text(settings.obj.find("#email").val());
                        $(settings.elLuckyNumber).text(data.eData.luckyNumber);
                    }
                }
            })
        } else {
            // settings.obj.find(settings.elStage.form).css({display: 'none'});
            $(settings.elStage.fail).addClass('active');
        }

    });

    settings.obj.find(settings.elRetry).bind("click", function(e){
        // settings.obj.find(settings.elStage.form).css({display: 'block'});
        $(settings.elStage.success).removeClass('active');
        $(settings.elStage.fail).removeClass('active');
    });

    if ($(settings.elTotal).length > 0) {
        var urlGet = domain + '/count-pre-register/' + settings.obj.find('input[name="data[event]"]').val() + ".json";
        $.ajax({
            url : urlGet,
            dataType : 'json', 
            method : 'POST',
            data : {},
            success: function (data) {
    
                var total = parseInt(data.total);

                if ($(settings.elCheatMultiple).length > 0) {
                    total *= parseInt($(settings.elCheatMultiple).val()); 
                }

                if ($(settings.elCheatAdd).length > 0) {
                    total += parseInt($(settings.elCheatAdd).val()); 
                }

                console.log(total);

                $(settings.elTotal).text(numberWithCommas(total));
                

                if ($(settings.elMileStones).length > 0) {

                    var device = {
                        width: $("body").outerWidth(),
                        height: $("body").outerHeight()
                    };

                    if (device.width > 841) { 
                        $(settings.elMileStones).css({
                            width: "0%"
                        })
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStones).css({
                                    width: settings.percentage.ajustDesktop[i] + "%"
                                })

                                $(settings.elRewards).find("li").eq(i).addClass("active");
                            }
                        }
                    } else {
                        $(settings.elMileStones).css({
                            height: "0px"
                        })
                        $(settings.elMileStonesMobile).css({
                            height: "0px"
                        })
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStones).css({
                                    height: settings.percentage.ajustMobile[i] + "px"
                                })

                                $(settings.elRewards).find("li").eq(i).addClass("active");
                            }
                        }
                        for (var i = 0, len = settings.percentage.milestones.length; i < len; i++) {
                            if (total >= settings.percentage.milestones[i]) {
                                $(settings.elMileStonesMobile).css({
                                    height: settings.percentage.ajustMobilePoint[i] + "px"
                                })
                            }
                        }
                    }
                }
            }
        });
    }

};

const validateEmail = function(email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return ((emailReg.test( email )) && (email.length > 4));
}
const validatePhone = function(phone) {
    var phoneReg = /^\d*$/;
    return ((phoneReg.test(phone)) && (phone.length == 10));
}
const numberWithCommas = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// function validateEmail(email) {
// }

// function validatePhone(phone) {
// }

// function numberWithCommas(x) {
// }