
// 
import "./components/_toggleClass";
import "./components/_lightbox";
import "./components/_preregister";
import "./components/_loadAjax";
import "./components/_tab";
import initIcons from "./components/_svg-icons";

$(function(){

    initIcons();

    $(".hamburger").toggleClass({
        toggle: [
            {
                el: $("nav"),
                className: 'active'
            },
            {
                el: $(".hamburger"),
                className: 'is-active'
            }
        ]
    });

    $(".sidebar__toggle").toggleClass({
        toggle: [
            {
                el: $(".sidebar"),
                className: 'active'
            }
        ]
    })

    // 
    
    if ($("[data-lightbox]").length > 0) {
        $("[data-lightbox]").each(function(){
            $(this).lightBox({
                objLightBox: $(this).attr('href'),
                type: $(this).data('lightbox-type')
            });
        }); 
    }

    // Use this for multiple-frame "contain" width scale mode

    $(window).on("resize", function(){
        
        $(".wrapperScale").each(function(){
            let settings = {
                design: {
                    width: 2100,
                    height: 1220
                },
                designMobile: {
                    width: 768,
                    height: 1200
                },
                device: {
                    width: $("html").innerWidth(), // 1366
                    height: $("html").innerHeight() // 1024
                }
            }

            // let ratioDesign = settings.design.width / settings.design.height;
            // let ratioDevice = settings.device.width / settings.device.height;
            let ratioHeight = settings.device.height / settings.design.height;
            let ratioWidth = 0;
            let scaleRatio;
            let marginLeft = 0;
            let marginTop = 0;

            if (settings.device.width >= 1024 && settings.device.width/settings.device.height > 1) {
                ratioWidth = settings.device.width / settings.design.width;
            } else {
                ratioWidth = settings.device.width / settings.designMobile.width;
            }


            scaleRatio = ratioWidth;

            // const isContainWidth = (ratioWidth > ratioHeight);

            // switch (isContainWidth) {
            //     case true: 
            //         scaleRatio = ratioHeight;
            //         marginLeft = (settings.device.width - ratioHeight * settings.design.width) / 2;
            //         break;
            //     case false: 
            //         scaleRatio = ratioWidth;
            //         // marginTop = (settings.device.height - ratioWidth * settings.design.height) / 2;
            //         break;
            // } 

            $(this).css({
                transform: "scale("+scaleRatio+")",
                marginTop: marginTop+"px",
                marginLeft: marginLeft+"px"
            });

            $(".popup__content").css({
                transform: "scale("+scaleRatio+")"
            });            
            
        });
    }).resize();

    $("input").on("input", function(){
        if ($(this).val().length > 0) {
            $(this).next("label").css({
                display: 'none'
            });
        } else {
            $(this).next("label").css({
                display: 'flex'
            });
        }

    });

    $(".outer").css({
        opacity: 1
    });

    // preregister

    
    $("[data-loadajax-core]").each(function(){
        $(this).loadAjax({
            language: {
                'loading': 'Loading...',
                'error': 'Loading error!',
                'noresult': 'There is no result!'
            }
        });
    });
});